export const columns = [
	{
		prop: 'name',
		label: '保险公司名称',
	},
	{
		prop: 'companyList',
		label: '所属公司',
		formatter: function (row, column, cellValue) {
			if (!cellValue || cellValue.length === 0) {
				return '无'
			}
			return cellValue.map(item => item.companyName).join(',')
		},
	},
	{
		prop: 'status',
		label: '合作状态',
		formatter: function (row, column, cellValue) {
			return cellValue === 1 ? '启用' : '禁用'
		},
	},
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '保险公司名称',
			attr: 'name',
			type: 'search',
			placeholder: '请输入保险公司名称',
			clearable: true,
		},
		{
			attr: 'companyIds',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '选择所属公司',
		},
		{
			attr: 'status',
			label: '状态',
			type: 'select',
			placeholder: '请选择状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
	status: [{ required: true, message: '请选择合作状态', trigger: 'blur' }],
	companyIds: [{ required: true, message: '请选择所属公司', trigger: 'blur' }],
}
